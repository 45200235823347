import React, { Component } from 'react';
import LeftSidebar from '../../../components/shared/left-sidebar';
import Header from '../../../components/shared/header';
import { Link } from 'react-router-dom';
import { request, API_URL, getToken } from '../../../constants/constants';
import UserContext from '../../../contexts/user';
import moment from 'moment';
import './game-detail.scss';
import Swal from 'sweetalert2';
import { DropzoneComponent } from 'react-dropzone-component';
import Pagination from '../../../components/pagination/pagination';
import Loader from '../../../components/loader/loader';
import ReactTable from 'react-table';
import Modal from 'react-responsive-modal';
import * as AWS from 'aws-sdk';
import Dropzone from 'react-dropzone';
// import * as S3 from 'aws-sdk/clients/s3';
    



class GameDetail extends Component {

	static contextType = UserContext;
	s3Client;

	constructor(props) {
		super(props);
		AWS.config.region = 'ap-southeast-2'; // Region
		AWS.config.update({
			accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
			secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
            region: process.env.REACT_APP_AWS_DEFAULT_REGION,
            maxRetries: 2,
            httpOptions: {
                timeout:  1800000000,
                // connectTimeout: 5000
            }
		});
		this.s3Client = new AWS.S3();
		this.state = {
			gender: [
				'Men',
				'Women',
				'Boys',
				'Girls',
				'Mixed'
			],
			gameDetails: {},
			teams: [],
			selectedTeams: [],
			currentSelectedTeams: [],
			modal: false,
			selected: {},
			pageSize: 10,
			currentPage: 1,
			selectedAll: {},
			indeterminateSign: false,
			isLoading: false,
			fileName: "",
			size: ""
		}
	}

	genderName(id) {
		switch (id) {
			case 0: return 'NULL';
			case 1: return 'MEN';
			case 2: return 'WOMEN';
			case 3: return 'BOYS';
			case 4: return 'GIRLS';
			case 5: return 'MIXED';
			default: return '';
		}
	}

	formatBytes(bytes, decimals = 2) {
		if (bytes === 0) return '0 Bytes';
	
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	
		const i = Math.floor(Math.log(bytes) / Math.log(k));
	
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	onDrop = (files) => {
		console.log(files);
		if(files.length <= 0) {
			Swal.fire(
				'Error',
				'Please upload a video file',
				'error'
			)
		} else {
			this.setState({files})
			const file = files[0]; 
			const extension = file.name.split('.')[1];
			const fileName = `${this.state.gameDetails.game.game_date}-${this.state.gameDetails.game.game_time}-${this.state.gameDetails.home_team.team_name}-VS-${this.state.gameDetails.away_team.team_name}.${extension}`
			const path = `${process.env.REACT_APP_AWS_VIDEO_PATH}/ORGANISATION-${this.context.user.current_org}/GAME-${this.props.match.params.id}/${fileName}`;
			const params = {Bucket: process.env.REACT_APP_AWS_BUCKET, Key: path, Body: file};
			this.setState({
				isUploading: true,
				fileName: file.name,
				size: file.size
			})
			this.s3Client.upload(params, (err, data) => {
				console.log(err, data);
			}).on('httpUploadProgress', (evt) => {
				console.log('Progress:', evt.loaded, '/', evt.total); 
				this.setState({
					progress: (evt.loaded/evt.total)*100,
					percentage: Math.floor((evt.loaded/evt.total)*100),
				})
			}).send((err, data) => { 
				console.log(err, data) 
				
				if(!err) {
					const data = {
						path: path,
						size: file.size
					}
					request('POST', `/auth/organisation/${this.context.user.current_org}/games/${this.props.match.params.id}/uploadLogs`, data, true).then(res => {
						console.log(res);
						Swal.fire(
							'Success',
							'Video Upload successfully!',
							'success'
						)
						this.setState({
							isUploading: false,
						})
						this.init();
					}).catch(err => {
						console.error(err);
						this.setState({
							isUploading: false,
						})
					})
					
				} else {
					Swal.fire(
						'Error',
						'There was an error uploading your video, please try again!',
						'error'
					)
					this.setState({
						isUploading: false,
					})
				}
			});
		}
	
	};


	componentDidMount() {
		this.init();
	}

	init() {
		this.getGameDetails().then(res => {
			console.log(res);
			this.getAllTeams().then(teams => {
				console.log(teams);
				this.setState({
					gameDetails: res.data.data,
					teams: teams.data.data
				})
			})
			
		})
	}

	getGameDetails() {
		return request(
			'GET',
			`/auth/organisation/${this.context.user.current_org}/games/${this.props.match.params.id}`,
			{},
			true
		)
	}

	getGameVideoPath(id) {
        return request(
            'GET',
            `/auth/organisation/${this.context.user.current_org}/games/${id}/video-path`,
            {},
            true
        )
    }

	handleDownload = e => {
		this.getGameVideoPath(this.props.match.params.id).then(res => {
            console.log(res);
            const path =  res.data.path;
            var params = {
                Bucket: process.env.REACT_APP_AWS_BUCKET, 
                Key: path,
                Expires: 10800, 
                // Range: "bytes=0-10000000"
            };
            this.s3Client.getSignedUrl('getObject', params, function (err, url) {
                console.log('The URL is', url);
                const a = document.createElement('A');
                a.href = url;
                a.download = url.substr(url.lastIndexOf('/') + 1);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
			});
        })
		// window.open(`/game/${this.props.match.params.id}/download`, '_blank');
	}

	handleDeleteVideo = e => {
		Swal.fire({
			title: "Are you sure you want to delete this video?",
			type: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes"
		}).then(data => {
			if(data.value) {
				this.setState({
					deleteVideoLoading: true,
				})
				request(
					'DELETE',
					`/auth/organisation/${this.context.user.current_org}/games/${this.props.match.params.id}/video`,
					{},
					true
				).then(res => {
					console.log(res)
					this.setState({
						deleteVideoLoading: false,
					})
					Swal.fire(
						'Success',
						res.data.message,
						'success'
					)
					this.init();
				}).catch(err => {
					console.error(err);
					this.setState({
						deleteVideoLoading: false,
					})
					Swal.fire(
						'Error',
						err.response.data.message,
						'error'
					)
				})
			}
		})
	}

	showModal = () => {
		this.setState({
			modal: true
		})
	}
	closeModal = () => {
		this.setState({
			modal: false
		})
	}

	toggleSelectAll() {
		let newSelected = {};
		let newSelectPage;
		const start = (this.state.currentPage - 1) * this.state.pageSize;
		const end = start + this.state.pageSize;
		if (this.state.selectedAll[this.state.currentPage] === false || this.state.selectedAll[this.state.currentPage] == null || this.state.indeterminateSign === true) {
			newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true});
			this.state.teams.slice(start, end).forEach(data => {
				newSelected[data.id] = true;
			});
			let newObj = Object.assign(this.state.selected, newSelected);
			// const selectedLeagues = Object.keys(newObj).filter(obj => {
			// 	return newObj[obj] === true;
			// });
			this.setState({
				selected: newObj,
				selectedAll: newSelectPage,
				indeterminateSign: false,
				currentSelectedTeams: [...this.state.teams]
			});
		} else {
			newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage] : false });
			this.state.teams.slice(start, end).forEach(data => {
				newSelected[data.id] = false;
			});
			let newObj = Object.assign(this.state.selected, newSelected);
			// const selectedGames = Object.keys(newObj).filter(obj => {
			// 	return newObj[obj] === true;
			// });

			// this.props.selectedGames(selectedGames);
			this.setState({
				selected: newObj,
				selectedAll: newSelectPage,
				currentSelectedTeams: []
			});
		}
	}
	toggleRow(team) {
		const teamId = team.id;
		const newSelected = Object.assign({}, this.state.selected);
		newSelected[teamId] = !this.state.selected[teamId];

		const index = this.state.currentSelectedTeams.findIndex(element => element.id == team.id);
		if(index < 0) {
			this.state.currentSelectedTeams.push(team);
		} else {
			this.state.currentSelectedTeams.splice(index, 1);
		}

		this.setState({
			currentSelectedTeams: this.state.currentSelectedTeams,
			selected: newSelected
		});
	}

	handleThProps(state, rowInfo, column, instance) {
		return {
			onClick: (e) => {
				if (column.sortable !== false) {
					if (column.className !== 'header-checkbox-toggle') {
						instance.sortColumn(column)
					}
				}
			}
		}
	}

	handlePageChange(data) {
		this.setState({ currentPage: data + 1 });
	}

	handlePageSizeChange(data) {
		if (this.state.pageSize < data) {
			this.setState({ pageSize: data }, () => {
				if (this.state.selectedAll[this.state.currentPage] === true) {
					this.setState({
						indeterminateSign: true
					})
				}
			});
		} else {
			this.setState({ pageSize: data }, () => {
				const start = (this.state.currentPage - 1) * this.state.pageSize;
				const end = start + this.state.pageSize;
				let newSelected = {};
				let newSelectPage;
				if (this.state.selectedAll[this.state.currentPage] === true) {
					newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true });
					this.state.teams.forEach(data => {
						newSelected[data.id] = false;
					});
					this.state.teams.slice(start, end).forEach(data => {
						newSelected[data.id] = true;
					});
					let newObj = Object.assign(this.state.selected, newSelected);
					const selectedList = Object.keys(newObj).filter(obj => {
						return newObj[obj] === true;
					});
					// this.props.selectedList(selectedList);
					this.setState({
						selected: newObj,
						selectedAll: newSelectPage,
						indeterminateSign: false
					});
				}
			});
        }
	}
	
	getAllTeams() {
		return request(
			'GET',
			`/auth/organisation/${this.context.user.current_org}/teams`,
			{},
			true
		)
	}

	handleAdd = () => {
		this.state.selectedTeams = [...this.state.selectedTeams, ...this.state.currentSelectedTeams];
		this.closeModal();
	}

	onFileChange = e => {
	
		console.log(e.target.files);
		const file = e.target.files[0];
		// const reader = new FileReader();

		// reader.onload = (e) =>  {
			// const arrayBuffer = reader.result;
			// console.log(arrayBuffer)
			const params = {Bucket: process.env.REACT_APP_AWS_BUCKET, Key: '/react/test3.mp4', Body: file};
			this.s3Client.upload(params, function(err, data) {
				console.log(err, data);
			}).on('httpUploadProgress', function(evt) {
				console.log('Progress:', evt.loaded, '/', evt.total); 
			  }).send(function(err, data) { console.log(err, data) });
		// }

		// reader.readAsArrayBuffer(file);
	}


	render() {
		const files = this.state.files && this.state.files.map(file => (
			<li key={file.name}>
			  {file.name} - {file.size} bytes
			</li>
		));
		let percentage = '0%';
		const currentOrg = this.context.user.current_org;
		const currentOrgIndex = this.context.user.organisations.findIndex(element => element.org_id === currentOrg);
		const permissions = this.context.user.organisations[currentOrgIndex].permissions;

		// const latitude = -35.2421201;
		// const longitude = 149.0572644;

		const componentConfig = {
			showFiletypeIcon: true,
			postUrl: `${API_URL}/auth/organisation/${this.context.user.current_org}/games/${this.props.match.params.id}/upload`
		};
		const djsConfig = {
			addRemoveLinks: true,
			headers: {
				"Authorization": `Bearer ${getToken()}`
			},
			paramName: 'video',
			// acceptedFiles: 'video/*',
			maxFilesize: 3000, 
			timeout: 9999999,
			dictDefaultMessage: '<p><span class="icon icon-upload"><svg style="font-size:3em" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cloud-upload-alt" class="svg-inline--fa fa-cloud-upload-alt fa-w-20 fa fa-file-excel" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z"></path></svg></span></p><p>Drop files here or click to upload.</p>'
			
		};

		const eventHandlers =  {
			
			thumbnail: (file) => {
				console.log(file)
			},
			uploadprogress: (file, progress, bytesSent) => {
				this.setState({
					percentage: `${Math.floor(progress == 100 ? progress - 1 : progress)}%`
				})
			},
			success: (res) => {
				console.log(res);
				this.getGameDetails().then(gameDetailsResult => {

					this.setState({
						isUploaded: true,
						percentage: '',
						gameDetails: gameDetailsResult.data.data
					})
					
					Swal.fire(
						'Success',
						'Video Upload successfully!',
						'success'
					).then(() => {
						this.init();
						this.setState({
							isUploaded: false
						})
					})

				})

			},
			maxfilesreached: (res) => {
				Swal.fire(
					'Error',
					'Max File size reached!',
					'error'
				)
			},
			error: (err) => {
				console.error(err);
				Swal.fire(
					'Error',
					'Upload error please try again!',
					'error'
				)
			}
		}
		const data = this.state.teams;
		const columns = [
			{
				Header: (header) => (
					<div className="custom-control custom-checkbox" style={{ marginLeft: 15 }}>
						<input
							type="checkbox"
							className="custom-control-input"
							id="selectAllCheckBox"
							checked={this.state.selectedAll[this.state.currentPage] || false}
							onChange={() => this.toggleSelectAll()}
							ref={input => {
								if (input) {
									input.indeterminate = this.state.indeterminateSign;
								}
							}}
						/>
						<label className="custom-control-label" htmlFor="selectAllCheckBox"></label>
					</div>
				),
				width: 52,
				Cell: row => (
					<div className="custom-control custom-checkbox" style={{ marginLeft: 15 }}>
						<input type="checkbox" className="custom-control-input" id={`game-id-${row.original.id}`} checked={this.state.selected[row.original.id] || false} onChange={() => this.toggleRow(row.original)} />
						<label className="custom-control-label" htmlFor={`game-id-${row.original.id}`}></label>
					</div>
				),
				className: 'header-checkbox-toggle',
				headerStyle: { padding: '1.07143em 0' },
				style: { padding: '0.71429em 0' }
				
			},
			{
				Header: 'Name',
				minWidth: 250,
				accessor: 'team_name'
			}
		]
		const teamId = this.state.gameDetails.game && this.state.gameDetails.game.home_team_id;
		return (
			<div className="game-detail">
				{/* {this.props.loading && <Loader />} */}
				<Modal
					open={this.state.modal}
					onClose={this.closeModal}
					showCloseIcon={false}
					center
					classNames={{
						modal: "modal fade show auto-width-modal"
					}}
				>
					<div className="modal-dialog modal-lg modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Select Team(s)</h5>
								<button type="button" className="close" onClick={this.closeModal}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								{this.props.modalLoader && <Loader />}
								<div className="row">
									<div className="col">
										<div className="table-area">
											{(this.state.isLoading) && <Loader />}
												<ReactTable
													PaginationComponent={Pagination}
													data={data}
													minRows={5}
													columns={columns}
													noDataText={this.state.isLoading ? '' : "No Teams Found."}
													defaultPageSize={this.state.pageSize}
													pageSizeOptions={[10, 25, 50, 100]}
													// resizable={true}
													onPageChange={(index) => this.handlePageChange(index)}
													onPageSizeChange={(size) => this.handlePageSizeChange(size)}
													getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
												/>
										</div>
									</div>
								</div>
							</div>

							<div className="modal-footer text-center">
								<button className="btn btn-orange" onClick={this.handleAdd}>Add</button>
							</div>
						</div>
					</div>
				</Modal>
				<div className="page-head mb_30 d-flex">
					<div className="px-3">
						<h1 className="page-title">{this.state.gameDetails.home_team && `${this.state.gameDetails.home_team.team_name} vs`} {this.state.gameDetails.away_team && this.state.gameDetails.away_team.team_name}</h1>
						<nav>
							<ol className="breadcrumb page-title">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item"><Link to="/games">Games</Link></li>
								<li className="breadcrumb-item current">{this.state.gameDetails.home_team && `${this.state.gameDetails.home_team.team_name} vs`} {this.state.gameDetails.away_team && this.state.gameDetails.away_team.team_name}</li>
							</ol>
						</nav>
					</div>
					<div className="">
						<Link to="/games"><button className="btn btn-white mr_15 mb-2">Back to all Games</button></Link>
						{ 	this.state.gameDetails.video && <button onClick={this.handleDownload} className="btn btn-orange mr_15 mb-2">Download Game</button>}
						{ permissions.administrator && this.state.gameDetails.video && <button onClick={this.handleDeleteVideo} className="btn btn-danger mb-2">Delete Video</button> }
					</div>
				</div>
				<div className="box upload">
					<div className="games mb_30">
						<div className="row">
							<div className="col">
								<table className="table-data">
									<thead>
										<tr>
											<th width="30%">League</th>
											<td width="70%">{this.state.gameDetails.league && this.state.gameDetails.league.league_name}</td>
										</tr>
										<tr>
											<th>Date / Time</th>
											<td>{this.state.gameDetails.game && `${moment(this.state.gameDetails.game.game_date).format("MMM DD, YYYY")} /`} {this.state.gameDetails.game && moment(this.state.gameDetails.game.game_time, "HH:mm").format("LT")}</td>
										</tr>
										<tr>
											<th>Venue</th>
											<td>{this.state.gameDetails.venue && this.state.gameDetails.venue.venue_name}</td>
										</tr>
										<tr>
											<th>Gender</th>
											<td>{this.state.gameDetails.game && this.genderName(this.state.gameDetails.game.gender)}</td>
										</tr>
									</thead>
								</table>
							</div>
							{/* <div className="col-xl-6 col-lg-6 col-md-12 col-12">
								<div className="map">
									<iframe title="map" src={`//maps.google.com/maps?q=${latitude}, ${longitude}&z=15&output=embed`} width="360" height="270" frameBorder="0" style={{ border: 0 }}></iframe>
								</div>
							</div> */}
						</div>
					</div>
					{
						permissions.administrator || permissions.upload.includes(teamId) ? (
							<div className="asign-game mb_30">
								<h2>Upload Game</h2>
								<div className="wrap">
									<div className="row">
										

										<div className="col">
											{/* <input type="file" onChange={this.onFileChange}/> */}

											{/* {
												!this.state.isUploaded && (
													<DropzoneComponent 
														config={componentConfig}
														djsConfig={djsConfig}
														eventHandlers={eventHandlers}
													>
														{this.state.percentage && 	<p className="percentage">{this.state.percentage}</p>}
													</DropzoneComponent>
												) 
											} */}
											   <Dropzone 
											   	className="drop-area d-flex align-items-center"
												   multiple={false}
												   accept="video/*"
												   onDrop={this.onDrop}
											   >
													{({getRootProps, getInputProps}) => (
													<section >
														<div className="dropzone">
														<div className="inner-text">
														{}
													
														{
															this.state.isUploading ? (
																<React.Fragment>
																<div className="preview">
																	{/* <div className="image"><img data-dz-thumbnail /></div> */}
																	<div className="details">    <div className="dz-size"><span data-dz-size>{this.state.size ? this.formatBytes(this.state.size) : ''}</span></div>    
																	<div className="filename"><span data-dz-name>{this.state.fileName || ''}</span></div></div>
																	<div>
																		<span>
																			{
																				this.state.percentage ? this.state.percentage + '%' : ''
																			}
																		</span>
																	</div>
																	<div className="progress"><span className="upload" data-dz-uploadprogress style={{width: (this.state.progress || 0) + '%'}} /></div>
																</div>
																<button className="dz-remove button-link" data-dz-remove>Cancel upload</button>
																</React.Fragment>
															)
															
															: (
																<React.Fragment>
																	<p>
																		<span className="icon icon-upload">
																			<svg style={{fontSize: '3em'}} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cloud-upload-alt" className="svg-inline--fa fa-cloud-upload-alt fa-w-20 fa fa-file-excel" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
																				<path fill="currentColor" d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z" />
																			</svg>
																		</span>
																	</p>
																	<p>Drop files here or click to upload.</p>
																</React.Fragment>
															)
														}
														</div>
														</div>
														<aside>
														{/* <h4>Files</h4> */}
														</aside>
													</section>
													)}
												</Dropzone>
										</div>
{/* 
										<div className="col-md-6">
											<div className="form-group">
												<div className="d-flex justify-content-between mb-4">
													<label className="label team">Teams</label>
													<button onClick={this.showModal} className="btn btn-orange" >
														Add
													</button>
												</div>
												<div className="box">
													<ul className="list-group">
														{
															this.state.selectedTeams.length ? this.state.selectedTeams.map( (element, i) => (
																<li key={i} className="list-group-item">{element.team_name}</li>
															)) : 'No Team Selected'
														}
													</ul>
												</div>
											</div>
										</div> */}
									</div>
								</div>
							</div>
						) : ''
					}
					
				</div>
			</div>
		);
	}
}

export default GameDetail;